import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
// iconos
import { Add } from "@mui/icons-material";

// configuración de la vista
import { storage } from "../../config";

import useDatabase from "../../Firebase/Hooks/useDatabase.js";

import firebase from "../../Firebase/firebase.js";
import { doc } from "firebase/firestore";
const dataSetProductos = [
  {
    nombre: "Carpeta Seminario Carta Color Negra",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSN00001",
    informacion_producto: "Carpeta Cosida Carta Curpiel Color Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Negro con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Carta Color Café",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSC00002",
    informacion_producto: "Carpeta Cosida Carta Curpiel Color Café ",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Café con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Carta Color Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSA00003",
    informacion_producto: "Carpeta Cosida Carta Curpiel Color Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Azul Marino con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Carta Color Miel",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSM00004",
    informacion_producto: "Carpeta Cosida Carta Curpiel Color Miel",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Miel con Interiores de Tela en Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Carta Color Vino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSV00005",
    informacion_producto: "Carpeta Cosida Carta Curpiel Color Vino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Vino con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Carta Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSCSN00006",
    informacion_producto: "Carpeta Cosida Sellada Carta de Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Negro Con Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Carta Curpiel Cafe",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSCSC00007",
    informacion_producto: "Carpeta Cosida Sellada Carta de Curpiel Cafe",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Cafe Con Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Carta Curpiel Vino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSCSV00008",
    informacion_producto: "Carpeta Cosida Sellada de Curpiel Vino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Vino Con Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Carta Curpiel Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSCSA00009",
    informacion_producto: "Carpeta Cosida Sellada Carta De Curpiel Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Azul Marino Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Carta Curpiel Miel",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CSCSM00010",
    informacion_producto: "Carperta Cosida Sellada Carta de Curpiel Miel",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Miel Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Color Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CON00011",
    informacion_producto: "Carpeta Cosida Curpiel Oficio Color Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Negro con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Color Café",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "COC00012",
    informacion_producto: "Carpeta Cosida Curpiel Oficio Color Café",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Café con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Color Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "COA00013",
    informacion_producto: "Carpeta Cosida Curpiel Oficio Color Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Azul Marino con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Color Miel",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "COM00014",
    informacion_producto: "Carpeta Cosida Curpiel Oficio Color Miel",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Miel con Interiores de Tela en Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Color Vino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "COV00015",
    informacion_producto: "Carpeta Cosida Curpiel Oficio Color Vino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Vino con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "COCSN00016",
    informacion_producto: "Carpeta Cosida Sellada Oficio Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Negro Con Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Curpiel Cafe",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "COCSC00017",
    informacion_producto: "Carpeta Cosida Sellada Oficio Curpiel Cafe",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Cafe Con Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Curpiel Vino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "COCSV00018",
    informacion_producto: "Carpeta Cosida Sellada Oficio Curpiel Vino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Vino Con Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Curpiel Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "COCSA00019",
    informacion_producto: "Carpeta Cosida Sellada Oficio Curpiel Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Azul Marino Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Curpiel Miel",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "COCSM00020",
    informacion_producto: "Carperta Cosida Sellada Oficio Curpiel Miel",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida Sellada Curpiel Por Fuera en Color Miel Interior en Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECN00021",
    informacion_producto: "Carpeta Cosida Esquela Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Negro con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Curpiel Cafe",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECC00022",
    informacion_producto: "Carpeta Cosida Esquela Curpiel Cafe",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Cafe con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECA00023",
    informacion_producto: "Carpeta Cosida Esquela Curpiel Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Azul Marino con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Curpiel Miel",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECM00024",
    informacion_producto: "Carpeta Cosida Esquela Curpiel Miel",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Miel con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Curpiel Vino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECV00025",
    informacion_producto: "Carpeta Cosida Esquela Curpiel Vino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Vino con Interiores de Tela Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECSN00026",
    informacion_producto: "Carpeta Cosida Sellada Esquela Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Negro con Interiores en Vinil Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Curpiel Cafe",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECSC00027",
    informacion_producto: "Carpeta Cosida Sellada Esquela Curpiel Cafe",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Cafe con Interiores en Vinil Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Curpiel Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECSA00028",
    informacion_producto: "Carpeta Cosida Sellada Esquela Curpiel Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Azul Marino con Interiores en Vinil Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Curpiel Miel",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECSM00029",
    informacion_producto: "Carpeta Cosida Sellada Esquela Curpiel Miel",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Miel con Interiores en Vinil Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Curpiel Vino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "CECSV00030",
    informacion_producto: "Carpeta Cosida Sellada Esquela Curpiel Vino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Carpeta Cosida de Curpiel Color Vino con Interiores en Vinil Color Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },

  {
    nombre: "Carpeta Seminario Sellada Carta Vinil Negro",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "CSSN00031",
    informacion_producto: "Carpeta Seminario Sellada de Vinil Negro",
    informacion_impresion: "Serigrafia",
    descripcion:
      "Carpeta Sellada de Vinil Color Negro con Interior Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Sellada Carta Vinil Vino",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "CSSV00032",
    informacion_producto: "Carpeta Seminario Sellada Vinil Vino",
    informacion_impresion: "Serigrafia",
    descripcion: "Carpeta Sellada de Vinil Color Vino con Interior Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Sellada Carta Vinil Azul Marino",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "CSSA00033",
    informacion_producto: "Carpeta Seminario Sellada Vinil Azul Marino",
    informacion_impresion: "Serigrafia",
    descripcion:
      "Carpeta Sellada de Vinil Color Azul Marino con Interior Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Vinil Negro",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "COSN00034",
    informacion_producto: "Carpeta Oficio Sellada Vinil Negro",
    informacion_impresion: "Serigrafia",
    descripcion:
      "Carpeta Sellada de Vinil Color Negro con Interior Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Vinil Vino",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "COSV00035",
    informacion_producto: "Carpeta Oficio Sellada Vinil Vino",
    informacion_impresion: "Serigrafia",
    descripcion: "Carpeta Sellada de Vinil Color Vino con Interior Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Oficio Vinil Azul Marino",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "COSN00036",
    informacion_producto: "Carpeta Oficio Sellada Vinil Azul Marino",
    informacion_impresion: "Serigrafia",
    descripcion:
      "Carpeta Sellada de Vinil Color Azul Marino con Interior Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Vinil Negro",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "CESN00037",
    informacion_producto: "Carpeta Esquela Sellada Vinil Negro",
    informacion_impresion: "Serigrafia",
    descripcion:
      "Carpeta Sellada de Vinil Color Negro con Interior Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Vinil Azul Marino",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "CESA00038",
    informacion_producto: "Carpeta Esquela Sellada Vinil Azul Marino",
    informacion_impresion: "Serigrafia",
    descripcion:
      "Carpeta Sellada de Vinil Color Azul Marino con Interior Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },
  {
    nombre: "Carpeta Seminario Esquela Vinil Vino",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "CESV00039",
    informacion_producto: "Carpeta Esquela Sellada Vinil Vino",
    informacion_impresion: "Serigrafia",
    descripcion: "Carpeta Sellada de Vinil Color Vino con Interior Vinil Negro",
    id_categoria: "BXrd8LX1Qk5O62LFa54I",
  },

  {
    nombre: "Porta Titulo Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "PTCN00040",
    informacion_producto: "Porta Titulos Cosido Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Porta Titulos Cosido Curpiel Color Negro Con Interior Tela Negro",
    id_categoria: "5FHKxCie5YdafxlxuzU2",
  },
  {
    nombre: "Porta Titulo Curpiel Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "PTCA00041",
    informacion_producto: "Porta Titulos Cosido Curpiel Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Porta Titulos Cosido Curpiel Color Azul Marino con Interior Tela Negro",
    id_categoria: "5FHKxCie5YdafxlxuzU2",
  },
  {
    nombre: "Porta Diplomas Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "PDCN00042",
    informacion_producto: "Porta Diplomas Cosido Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Porta Diplomas Cosido Curpiel Color Negro con Interior Tela Negro",
    id_categoria: "5FHKxCie5YdafxlxuzU2",
  },
  {
    nombre: "Porta Diplomas Curpiel Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "PDCA00043",
    informacion_producto: "Porta Diplomas Cosido Curpiel Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Porta Diplomas Cosido Curpiel Color Azul Marino con Interior Tela Negro",
    id_categoria: "5FHKxCie5YdafxlxuzU2",
  },
  {
    nombre: "Porta Cuentas Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "PCCN00044",
    informacion_producto: "Porta Cuentas Cosido Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion: "Porta Cuentas Cosido Curpiel Color Negro Con Interior Negro",
    id_categoria: "5FHKxCie5YdafxlxuzU2",
  },
  {
    nombre: "Porta Cuentas Curpiel Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "PCCA00045",
    informacion_producto: "Porta Cuentas Cosido Curpiel Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Porta Cuentas Cosido Curpiel Color Azul Marino Con Interior Negro",
    id_categoria: "5FHKxCie5YdafxlxuzU2",
  },
  {
    nombre: "Porta Menú Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "PMCN00046",
    informacion_producto: "Porta Menu Cosido Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Porta Menú Cosido Curpiel Color Negro Con Interiores en Negro y Guardas de Vinil ",
    id_categoria: "5FHKxCie5YdafxlxuzU2",
  },
  {
    nombre: "Agenda Semanal Sencilla Curpiel Negra",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "ASCN00047",
    informacion_producto: "Agenda Semanal Cosida Curpiel Negra",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Agenda Sencilla Cosida Curpiel Color Negro con Interiores en Tela Negro ",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },
  {
    nombre: "Agenda Semanal Sencilla Curpiel Azul Marino",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "ASCA00048",
    informacion_producto: "Agenda Semanal Cosida Curpiel Azul Marino",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Agenda Sencilla Cosida Curpiel Color Azul Marino con Interiores en Tela Negro ",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },
  {
    nombre: "Agenda Semanal Sencilla Curpiel Café",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "ASCC00049",
    informacion_producto: "Agenda Semanal Cosida Curpiel Café",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Agenda Sencilla Cosida Curpiel Color Café Con Interiores en Tela Negra",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },

  {
    nombre: "Agenda Semanal Multifuncional Quemante Negro",
    material: "pMKnVqtWdA8DwYAxKpzd",
    codigo: "ASMA00050",
    informacion_producto: "Agenda Semanal Multifuncional Quemante Azul",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Agenda Multifuncional Cosida Quemante Color Azul con Interiores en Quemante Azul",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },
  {
    nombre: "Agenda Semanal Multifuncional Quemante Gris",
    material: "pMKnVqtWdA8DwYAxKpzd",
    codigo: "ASMG00051",
    informacion_producto: "Agenda Semanal Multifuncional Quemante Gris",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Agenda Multifuncional Cosida Quemante Color Gris con Interiores en Quemante Gris",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },
  {
    nombre: "Agenda Semanal Multifuncional Quemante Miel",
    material: "pMKnVqtWdA8DwYAxKpzd",
    codigo: "ASMM00052",
    informacion_producto: "Agenda Semanal Multifuncional Quemante Miel",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Agenda Multifuncional Cosida Quemante Color Miel con Interiores en Quemante Miel",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },
  {
    nombre: "Agenda Semanal Multifuncional Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    informacion_producto: "Agenda Semanal Multifuncional Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Agenda Multifuncional Cosida Curpiel Color Negro con Interiores en Curpiel Negro",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },
  {
    nombre: "Agenda Diaria Cosida Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "ADCN00054",
    informacion_producto: "Agenda Diaria de Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion: "Agenda Diaria Cosida Curpiel Color Negro",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },
  {
    nombre: "Agenda Diaria Espiral Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "ADEN00055",
    informacion_producto: "Agenda Diaria de Curpiel Negro Espiral",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion: "Agenda Diaria con Espiral en Color Negro",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },
  {
    nombre: "Agenda Diaria Bolsillo Colores",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "ADBC00056",
    informacion_producto: "Agenda Diaria de Curpiel de Bolsillo",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Agenda Diaria Cosida Curpiel Varios Colores Tamaño de Bolsillo",
    id_categoria: "TEAGS53ChTAslivlNyKE",
  },
  {
    nombre: "Libreta Moleskine Curpiel Colores",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "LMC00057",
    informacion_producto: "Libreta Moleskine Curpiel en Colores",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Libreta Moleskine Diferentes Colores en Curpiel Tamaño Esquela",
    id_categoria: "q6bvRiAjC70fsYfxUuNn",
  },

  {
    nombre: "Libreta Moleskine Quemante Colores",
    material: "pMKnVqtWdA8DwYAxKpzd",
    codigo: "LMQ00058",
    informacion_producto: "LIbreta Moleskine Quemante en Colores",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "Libreta Moleskine Diferentes Colores en Quemante Tamaño Esquela",
    id_categoria: "q6bvRiAjC70fsYfxUuNn",
  },
  {
    nombre: "Libreta Espiral Curpiel Colores",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "LECC00059",
    informacion_producto: "Libreta Espiral Curpiel Colores Esquela",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion: "Libreta Espiral Diferentes Colores en Curpiel Tamaño Esquela",
    id_categoria: "q6bvRiAjC70fsYfxUuNn",
  },
  {
    nombre: "Libreta Espiral Curpiel Colores Carta",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "LECC00059",
    informacion_producto: "Libreta Espiral Curpiel Colores Carta",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion: "Libreta Espiral Diferentes Colores en Curpiel Tamaño Carta",
    id_categoria: "q6bvRiAjC70fsYfxUuNn",
  },
  {
    nombre: "Tarjetero Quemante Colores",
    material: "pMKnVqtWdA8DwYAxKpzd",
    codigo: "TQC00060",
    informacion_producto: "Tarjetero Quemante Cosido Colores",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion: "Tarjetero de Quemante Para 6 Tarjetas",
    id_categoria: "5jKqfNR3tY3aYZn7bM26",
  },
  {
    nombre: "Porta Gafetes Curpiel Negro",
    material: "6N6DPVfsBvKFYWNty86b",
    codigo: "PGCN00061",
    informacion_producto: "Porta Gafetes Curpiel Negro",
    informacion_impresion:
      "Grabado Laser, Calor Bajo Relieve, Serigrafia,Hot Stamping",
    descripcion:
      "POrta Gafetes en Curpiel Negro Con el Frente en Vinil Cristal",
    id_categoria: "gMfHiXSymO2GBFa82RLC",
  },

  {
    nombre: "Cilindro Metalico",
    material: "ayu2yFfxoVnDGH2noRJ3",
    codigo: "CM00062",
    informacion_producto: "Cilindro Metalico Acabado Metalico y Brillante",
    informacion_impresion:
      "Grabado Laser, Vini Aderible, Serigrafia, DTF UV, Sublimación",
    descripcion:
      "Cilindro Metalico Acabados Metalico y Brillantes con Gancho Para Colgar",
    id_categoria: "H8EaTs7C6WWNWuE99jom",
  },
  {
    nombre: "Cilindro Plastico",
    material: "tnBr81SrMDPDDDhTlRNT",
    codigo: "CP000063",
    informacion_producto: "Cilindro de Plastico en Diferentes Colores",
    informacion_impresion: "Vinil Aderible, Serigrafia, DTF UV",
    descripcion: "Cilindro Plastico Transparente en Diferentes Colores ",
    id_categoria: "H8EaTs7C6WWNWuE99jom",
  },
  {
    nombre: "Cilindro de Vidrio",
    material: "Zy3M7AuBJbOiPWpPJi28",
    codigo: "CV00064",
    informacion_producto: "Cilindro de Vidrio Resistente Con Tapa Aluminio",
    informacion_impresion: "Vinil Aderible, Serigrafia, DTF UV, Tampografia",
    descripcion: "Cilindro de Vidrio *****",
    id_categoria: "H8EaTs7C6WWNWuE99jom",
  },

  {
    nombre: "Mochila Ejecutiva Poliester",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "MEP00065",
    informacion_producto: "Sin informacion",
    informacion_impresion: "Sin informacion",
    descripcion: "Sin informacion",
    id_categoria: "5Rc71FiehYrQV2dBdW2X",
  },
  {
    nombre: "Bolsa de Manta Natural",
    material: "eHE0mqziSo01bzo5kVXs",
    codigo: "BMN00069",
    informacion_producto: "Bolsa de Manta Natural Tipo Tote Bag",
    informacion_impresion: "Serigrafia, DTF, Sublimación, Vinil Aderible",
    descripcion: "Bolsa de Manta Natural, Medidas de 38x40 cm con asa de 60cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa de Loneta Natural",
    material: "8B1QpLEGL6X8s8KP9aV0",
    codigo: "BLN00070",
    informacion_producto: "Bolsa de Loneta Natural",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion: "Bolsa de Loneta Natural, Medidas de 40x40 cm con Asa de 60cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa de Loneta Negra",
    material: "8B1QpLEGL6X8s8KP9aV0",
    codigo: "BLNE00071",
    informacion_producto: "Bolsa de Loneta Negra",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa de Loneta Color Negro, Medidas de 40x40 cm con Asa de 60cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Grande Poliester Asa de Tubo",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMPGAT00072",
    informacion_producto: "Bolsa Mandadera Poliester Grande Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Grande de Poliester Asa de Tubo, Medidas 39x34cm, Fuelle de 12cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Grande Canasta Asa de Tubo",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMGCAT00073",
    informacion_producto: "Bolsa Mandadera Canasta Grande Asa de Tubo",
    informacion_impresion: "Serigrafia,DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Grande en Canasta Asa de Tubo, Medidas 39x34cm, Fuelle de 12cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Grande Poliester Asa de Cinta",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMGPAC00074",
    informacion_producto: "Bolsa Mandadera Poliester Grande Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Grande de Poliester Asa de 65cm, Medidas 39x34cm, Fuelle de 12cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },

  {
    nombre: "Bolsa Mandadera Grande Canasta Asa de Cinta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMGCAC00075",
    informacion_producto: "Bolsa Mandadera Canasta Grande Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Grande de Canasta Asa de Cinta, Medidas 39x34cm, Fuelle de 12cm Asa de 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Mediana Poliester asa de Tubo",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMMPAT00076",
    informacion_producto: "Bolsa Mandadera Poliester Mediana Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Mediana de Poliester Asa de Tubo, Medidas 34.5x30cm, Fuelle de 12cm ",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Mediana Canasta Asa de Tubo",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMMCAT00077",
    informacion_producto: "Bolsa Mandadera Canasta Mediana Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Panera en Canasta Asa de Tubo, Medida 26x30cm, Fuelle de 12cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Mediana Poliester Asa de Cinta",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMMPAC00078",
    informacion_producto: "Bolsa Mandadera Poliester Mediana Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Mediana de Poliester Asa de Cinta, Medidas 34.5x30cm, Fuelle de 12cm Asa de 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Mediana Canasta Asa de Cinta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMMCAC00079",
    informacion_producto: "Bolsa Mandadera Canasta Mediana Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Mediana en Canasta Asa de Cinta, Medidas 34.5x30, Fuelle de 12cm Asa de 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Panera Poliester Asa de Tubo",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMPPAT00080",
    informacion_producto: "Bolsa Mandadera Poliester Panera Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Panera de Poliester Asa de Tubo, Medida 26x30cm, Fuelle de 12cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Panera Canasta Asa de Tubo",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMPCAT00081",
    informacion_producto: "Bolsa Mandadera Canasta Panera Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Panera en Canasta Asa de Tubo, Medida 26x30cm, Fuelle de 12cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Panera Poliester Asa de Cinta",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMPPAC00082",
    informacion_producto: "Bolsa Mandadera Poliester Panera Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Panera Poliester Asa de Cinta, Medidad 26x30cm, Fuelle de 12cm Asa de 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Panera Canasta Asa de Cinta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMPCAC00083",
    informacion_producto: "Bolsa Mandadera Canasta Panera Asa de Cinta",
    informacion_impresion: "Serigrafia,DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Panera Canasta Asa de Cinta, Medida 26x30, Fuelle de 12cm Asa de 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Chica Poliester Asa de Tubo",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMCPAT00084",
    informacion_producto: "Bolsa Mandadera Poliester Chica Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Chica Poliester Asa de Tubo, Medida 30x25cm, Fuelle de 10 cm ",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Chica Canasta Asa de Tubo",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMCCAT00085",
    informacion_producto: "Bolsa Mandadera Canasta Chica Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Chica Canasta Asa de Tubo, Medida 30x25cm, Fuelle de 10 cm ",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Chica Poliester Asa de Cinta",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMCPAC00086",
    informacion_producto: "Bolsa Mandadera Poliester Chica Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Chica Poliester Asa de Cinta, Medida 30x25cm, Fuelle de 10 cm, Asas de 55 cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Chica Canasta Asa de Cinta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMCCAC00087",
    informacion_producto: "Bolsa Mandadera Canasta Chica Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Chica Canasta Asa de Cinta, Medida 30x25cm, Fuelle de 10 cm, Asas de 55 cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Infantil Poliester Asa de Tubo",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMIPAT00088",
    informacion_producto: "Bolsa Mandadera Poliester Infantil Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Infatil Poliester Asa de Tubo, Medida 20x15.5, Fuelle de 10 cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Infantil Canasta Asa de Tubo",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMICAT00089",
    informacion_producto: "Bolsa Mandadera Infantil Canasta Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Infantil Canasta Asa de Tubo, Medida 20x15.5cm, Fuelle de 10 cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Infantil Poliester Asa de Cinta",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMIPAC00090",
    informacion_producto: "Bolsa Mandadera Poliester Infantil Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Infantil Poliester Asa de Cinta, Medida 20x15,5cm, Asa de 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Mandadera Infantil Canasta Asa de Cinta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMICAT00091",
    informacion_producto: "Bolsa Mandadera Infantil Canasta Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Mandadera Infantil Canasta Asa de Cinta, Medida 20x15,5cm, Asa de 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Grande Poliester Asa de Tubo",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BRGPAT00092",
    informacion_producto: "Bolsa Redonda Grande Poliester Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Grande Poliester Asa de Tubo, Medida 39x34, Fuelle 12 cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Grande Canasta Asa de Tubo",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BRGCAT00093",
    informacion_producto: "Bolsa Redonda Grande Canasta Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Grande Canasta Asa de Tubo, Medida 39x34, Fuelle 12 cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Grande Poliester Asa de Cinta",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BRGPAC00094",
    informacion_producto: "Bolsa Redonda Grande Poliester Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Grande Poliester Asa de Cinta, Medida 39x34, Fuelle 12 cm, Asa 55 cm ",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Grande Canasta Asa de Cinta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BRGCAC00095",
    informacion_producto: "Bolsa Redonda Grande Canasta Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Grande Canasta Asa de Cinta 39x34, Fuelle 12 cm, Asa 55cm.",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Mediana Poliester Asa de Tubo",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BRMPAT00096",
    informacion_producto: "Bolsa Redonda Mediana Poliester Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Mediana Poliester Asa de Tubo, Medida 34.5x30, Fuelle 12cm ",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Mediana Canasta Asa de Tubo",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BRMCAT00097",
    informacion_producto: "Bolsa Redonda Mediana Canasta Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Mediana Canasta Asa de Tubo, Medida 34.5x30, Fuelle 12cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Mediana Poliester Asa de Cinta",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BRMPAC00098",
    informacion_producto: "Bolsa Redonda Mediana Poliester Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Mediana Poliester Asa de Cinta, Medida 34.5x30, Fuella de 12cm, Asa 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Mediana Canasta Asa de Cinta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BRMCAC00099",
    informacion_producto: "Bolsa Redonda Mediana Canasta Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Mediana Canasta Asa de Cinta, Medida 34.5x30, Fuelle de 12cm, Asa 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Chica Poliester Asa Tubo",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BRCPAT00100",
    informacion_producto: "Bolsa Redonda Chica Poliester Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Chica Poliester Asa de Tubo, Medida 30x26, Fuelle de 10cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Chica Canasta Asa de Tubo",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BRCCAT00101",
    informacion_producto: "Bolsa Redonda Chica Canasta Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Chica Canasta Asa de Tubo, Medida 30x26, Fuelle de 10cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Redonda Chica Poliester Asa de Cinta",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BRCPAC00102",
    informacion_producto: "Bolsa Redonda Chica Poliester Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Redonda Chica Poliester Asa de Cinta, Medida 30x26, Fuelle de 10cm, Asa de 55cm ",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Media Luna Grande Poliester Asa de Tubo",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMLGPAT00108",
    informacion_producto: "Bolsa Media Luna Grande de Poliester Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Media Luna Grande Poliester Asa de Tubo, Medida 37x29, Fuelle de 12cm, Asa de Tubo",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Media Luna Grande Canasta Asa de Tubo",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMLGCAT00109",
    informacion_producto: "Bolsa Media Luna Grande de Canasta Asa de Tubo",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Media Luna Grande Poliester Asa de Tubo, Medida 37x29, Fuelle de 12cm, Asa de Tubo",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Media Luna Grande Poliester Asa de Cinta",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "BMLGPAC00110",
    informacion_producto: "Bolsa Media Luna Grande de Poliester Asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Media Luna Grande Poliester Asa de Cinta, Medida 37x29, Fuelle de 12cm, Asa de Cinta de 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Media Luna Grande Canasta Asa de Cinta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BMLGCAC00111",
    informacion_producto: "Bolsa Media Luna Grande de Canasta asa de Cinta",
    informacion_impresion: "Serigrafia, DTF, Vinil Aderible",
    descripcion:
      "Bolsa Media Luna Grande Poliester Asa de Cinta, Medida 37x29, Fuelle de 12cm, Asa de Cinta de 55cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },

  {
    nombre: "Bolsa Yute Grande",
    material: "aEj9HPDH0MgH4QRQjhl7",
    codigo: "BYG00112",
    informacion_producto: "Bolsa de Yute Grande",
    informacion_impresion: "Vinil, Serigrafia, DTF",
    descripcion: "Bolsa Yute Grande Medidas de 45x35 fuelle de 20cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa de Yute Chica",
    material: "aEj9HPDH0MgH4QRQjhl7",
    codigo: "BYC00113",
    informacion_producto: "Bolsa de Yute Chica ",
    informacion_impresion: "Vinil, Serigrafia, DTF",
    descripcion: "Bolsa de Yute Chica Medidas de 30x30 y Fuelle de 20cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Morral Grande Semicanasta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "MGSC00114",
    informacion_producto: "Morral Grande Semicanasta",
    informacion_impresion: "Sublimacion, Vinil, DTF, Serigrafia",
    descripcion: "Morral Grande Semicanasta Medidas de 40x35cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Morral Chico Semicanasta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "MCS00115",
    informacion_producto: "Morral Chico Semicanasta",
    informacion_impresion: "Sublimacion, Vinil, DTF, Serigrafia",
    descripcion: "Morral Chico Semicanasta, Medidas de 26x30cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },

  {
    nombre: "Morral Grande Ecologico ",
    material: "KHHd5CP4M1eaP4dQIWSN",
    codigo: "MGE00116",
    informacion_producto: "Morral Grande Ecologico ",
    informacion_impresion: "Serigrafia, DTF",
    descripcion:
      "Morral Grande Ecologico, Material Non Woven Medidas de 40x35cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Morral Chico Ecologico",
    material: "KHHd5CP4M1eaP4dQIWSN",
    codigo: "MCE00117",
    informacion_producto: "Morral Chico Ecologico ",
    informacion_impresion: "Serigrafia, DTF",
    descripcion:
      "Morral Chico Ecologico, Meterial Non Woven Medidas de 26x30cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Wallmart",
    material: "KHHd5CP4M1eaP4dQIWSN",
    codigo: "BW00118",
    informacion_producto: "Bolsa Ecologica Tipo Wallmart",
    informacion_impresion: "Serigrafia, DTF",
    descripcion:
      "Bolsa Wallmart, Material Non Woven, Medidas de 35x29cm con Fuelle de 12cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Ecologica Plana",
    material: "KHHd5CP4M1eaP4dQIWSN",
    codigo: "BEP00119",
    informacion_producto: "Bolsa Ecologica Plana ",
    informacion_impresion: "Serigrafia, DTF",
    descripcion: "Bolsa Ecologica Plana, Material Non Woven, Medidas de 38x36",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Ecologica Panera ",
    material: "KHHd5CP4M1eaP4dQIWSN",
    codigo: "BETP00120",
    informacion_producto: "Bolsa Ecologica Panera ",
    informacion_impresion: "Serigrafia, DTF",
    descripcion:
      "Bolsa Ecologica Panera, Material Non Woven, Medidas de 26x30cm fuelle de 10cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },
  {
    nombre: "Bolsa Ecologica Jumbo ",
    material: "KHHd5CP4M1eaP4dQIWSN",
    codigo: "BEJ00121",
    informacion_producto: "Bolsa Ecologica Jumbo",
    informacion_impresion: "Serigrafia; DTF",
    descripcion:
      "Bolsa Ecologica Jumbo, Material Non Woven, Medidas de 40x45cm Fuelle de 16cm",
    id_categoria: "pSM9oaoxY4Zsu6kQqg4v",
  },

  {
    nombre: "Lonchera Poliester",
    material: "Btq9G907PFP4TEpaEAgb",
    codigo: "LP00122",
    informacion_producto: "Hielera de Poliester",
    informacion_impresion: "Sublimacion, Vinil, DTF, Serigrafia",
    descripcion:
      "Hielera de Poliester con Forro Interior Termico, Cierre Superior con una Bolsa Frontal y Asa Ajustable",
    id_categoria: "yXAttidj9kY5PUMKgyTz",
  },
  {
    nombre: "Lonchera Canasta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "LC00123",
    informacion_producto: "Hielera de Canasta",
    informacion_impresion: "Sublimacion, Vinil, DTF, Serigrafia",
    descripcion:
      "Hielera de Canasta con Forro Interior Termico, Cierre Superior con una Bolsa Frontal y Asa Ajustable",
    id_categoria: "yXAttidj9kY5PUMKgyTz",
  },
  {
    nombre: "Chaleco de Brigadista Gabardina Unitalla",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "CBG00124",
    informacion_producto: "Chaleco Brigadista de Gabardina Unitalla Ajustable",
    informacion_impresion: "Bordado, Serigrafia, DTF, Vinil",
    descripcion:
      "Chaleco Brigadista de Gabardina, Unitalla Ajustable con Cintas Reflejantes ",
    id_categoria: "F4Dih1q8ufY6cXFq3cIF",
  },
  {
    nombre: "Chaleco Construccion Nylon",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "CCN00125",
    informacion_producto:
      "Chaleco de Construccion de Red con cintas Reflejantes Tallas CH, M, G, EG",
    informacion_impresion: "Serigrafia, Vinil",
    descripcion:
      "Chaleco de Construccion de Red, con Cintas Reflejantes, Por Tallas",
    id_categoria: "F4Dih1q8ufY6cXFq3cIF",
  },
  {
    nombre: "Chaleco Contruccion Repelente",
    material: "XWtnERsPUm2inPGKKzNZ",
    codigo: "CCR00126",
    informacion_producto:
      "Chaleco de Construccion de Material Repelente Tallas CH, M, G, EG",
    informacion_impresion: "Bordado, Serigrafia, DTF, Vinil",
    descripcion: "Chaleco de Construccion de Material Repelente, Por Tallas",
    id_categoria: "F4Dih1q8ufY6cXFq3cIF",
  },

  {
    nombre: "Impermeable Gaban ",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "IG00127",
    informacion_producto: "Impermeable Gaban Unitalla",
    informacion_impresion: "Serigrafia",
    descripcion: "Impermeable Gaban Unitalla,Tipo Poncho",
    id_categoria: "1xmnvy4ayqbFEUw23s7T",
  },
  {
    nombre: "Impermeable Broche ",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "IB00128",
    informacion_producto: "Impermeable de Broche Unitalla",
    informacion_impresion: "Serigrafia",
    descripcion: "Impermeable de Broche Unitalla, Tipo Gabardina",
    id_categoria: "1xmnvy4ayqbFEUw23s7T",
  },
  {
    nombre: "Impermeable Motociclista",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "IM00129",
    informacion_producto: "Impermeable de Motociclista, Tallas: CH, M, G, EG",
    informacion_impresion: "Serigrafia",
    descripcion:
      "Impermeable en 2 Piezas( Pantalon y Chamarra) Por tallas y con Cintas Reflejantes",
    id_categoria: "1xmnvy4ayqbFEUw23s7T",
  },
  {
    nombre: "Playera Algodon 120grs",
    material: "bauAMZ7cMTnhIhQDKsre",
    codigo: "PA120GRS00130",
    informacion_producto: "Playera Algodon de 120 gramos Color Blanco",
    informacion_impresion: "Serigrafia",
    descripcion: "Playera de 120 Gramos en Color Blanco Unitalla",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },
  {
    nombre: "Playera Algodon 140grs",
    material: "bauAMZ7cMTnhIhQDKsre",
    codigo: "PA140GRS00131",
    informacion_producto: "Playera Algodon de 140 gramos Color Blanco",
    informacion_impresion: "Serigrafia",
    descripcion: "Playera de 140 Gramos en Color Blanco Unitalla",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },
  {
    nombre: "Palyera Algodon 160grs",
    material: "bauAMZ7cMTnhIhQDKsre",
    codigo: "PA160GRS00132",
    informacion_producto: "Playera Algodon de 160 gramos Color Blanco",
    informacion_impresion: "Serigrafia",
    descripcion: "Playera de 160 Gramos en Color Blanco Unitalla",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },

  {
    nombre: "Playera Dri-Fit",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "PDF00133",
    informacion_producto: "Playera Dri-Fit por Tallas: CH, M, G, EG",
    informacion_impresion: "Serigrafia, Sublimacion, DTF, Vinil Aderible",
    descripcion: "Playera Dri-Fit por Tallas: CH, M, G, EG",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },
  {
    nombre: "Playera Brush",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "PBR00134",
    informacion_producto: "Playera Brush Para Sublimar en Color Blanco",
    informacion_impresion: "Serigrafia, Sublimacion, DTF, Vinil Aderible",
    descripcion: "Playera Brush Para Sublimar en Color Blanco Unitalla",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },
  {
    nombre: "PLayera Bombay",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "PBO00135",
    informacion_producto: "Playera Bombay Para Sublimar 100% Poliester",
    informacion_impresion: "Serigrafia, Sublimacion, DTF, Vinil Aderible",
    descripcion: "Playera Bombay Para Sublimar 100% Poliester",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },
  {
    nombre: "Playera Tacto Algodon",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "PTA00136",
  },
  {
    nombre: "Gorra Tafetan",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "GTA00137",
    informacion_producto: "Gorra en Material Tafetan",
    informacion_impresion: "Serigrafia, Sublimacion, DTF, Vinil Aderible",
    descripcion: "Gorra en Material Tafetan ",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },
  {
    nombre: "Gorra Trucker",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "GTR00138",
    informacion_producto:
      "Gorra Trucker, Frente para Sublimar y parte Trasera de Red",
    informacion_impresion: "Serigrafia, Sublimacion, DTF, Vinil Aderible",
    descripcion: "Gorra Trucker, Frente para Sublimar y parte Trasera de Red",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },
  {
    nombre: "Bandera Tafetan",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BT00139",
    informacion_producto: "Bandera Tafetan Sublimable",
    informacion_impresion: "Sublimacion, Serigrafia",
    descripcion:
      "Bandera de la medida exacta Para la Necesidad del Cliente en Color Blanco Para Sublimar",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },
  {
    nombre: "Bandera de Semicanasta",
    material: "Bb4DFwsroOlBR1pE4c42",
    codigo: "BSC00140",
    informacion_producto: "Bandera de Semicanasta",
    informacion_impresion: "Sublimacion, Serigrafia",
    descripcion:
      "Bandera de la medida exacta Para la Necesidad del Cliente en Color Blanco Para Sublimar",
    id_categoria: "R2OZIQQhTc6WTgsc6yZr",
  },
];

const NuevaSubCategoria = () => {
  const db = firebase.firestore;
  const [indexState, setIndex] = useState(0);

  const { id } = useParams();

  const { status } = useSelector((state) => state.firestore);

  const [consulta, setConsulta] = useState({ consulta: "" });

  const [imagenes, setImagenes] = useState([]);
  const [filesData, setFiles] = useState([]);

  const [state, setState] = useState({
    nombre: "",
    categoria_id: id,
  });

  useDatabase(consulta);

  const agregarSubCategoria = (e) => {
    e.preventDefault();

    setConsulta({
      consulta: "agregarDocumento",
      collectionName: "subCategorias",
      state,
      files: filesData,
    });
  };

  // useEffect(() => {
  //   console.log({ status });
  // }, [status]);

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const leerImagen = (e) => {
    let imagenesPrecarga = [...imagenes];
    let files = [...filesData];
    let indexGlobal = 0;

    if (e.target.name === "icono") {
      indexGlobal = 1;
    }

    files[indexGlobal] = e.target.files[0];

    setFiles(files);

    let fileReader = new FileReader();
    fileReader.addEventListener("load", function (evt) {
      imagenesPrecarga[indexGlobal] = fileReader.result;

      setImagenes(imagenesPrecarga);
    });

    fileReader.readAsDataURL(e.target.files[0]);
  };

  //  --------------------------------

  useEffect(() => {
    console.log({ status });

    if (status === "succeeded") {
      console.log("subido");
      setConsulta({ consulta: "reset" });
      console.log("----------------");
      setIndex(indexState + 1);
    } else {
      console.log("error");
    }
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    console.log({ indexState });
    console.log(dataSetProductos.length);
    // cargarInfo(indexState);
  }, [indexState]);

  const subirProducto = (stateParams) => {
    console.log("subiendo ....");

    setConsulta({
      consulta: "agregarDocumento",
      collectionName: "productos",
      state: stateParams,
    });
  };

  const cargarInfo = (index) => {
    console.log("cargar info: ", index);

    let actual = dataSetProductos[index];

    console.log({ actual });

    const referencia = doc(db, "materiales", actual.material);

    console.log(referencia);

    let body = {
      decripcion:
        "<p>" +
        actual.descripcion +
        ".</p><p><strong>Información material:</strong></p><p>" +
        actual.informacion_impresion +
        ".</p><p><strong>Información producto:</strong></p><p>" +
        actual.informacion_producto +
        "</p>",
      id_materiales: [referencia],
      id_sub_categoria: actual.id_categoria,
      mensajeWhats: "Me podrias dar información de " + actual.nombre,
      nombre: actual.nombre,
      imagenes: [],
      precioMayoreo: 99999,
      precioMenudeo: 99999,
    };

    // console.log(actual, body);

    subirProducto(body);
  };

  //  --------------------------------

  return (
    <div className="row contenedor-1">
      <button
        onClick={() => {
          cargarInfo(0);
        }}
        className="boton-verde"
      >
        Cargar
      </button>

      <form onSubmit={agregarSubCategoria}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="titulo-1">Añadir nueva sub categoria</p>

          <div style={{ display: "flex", gap: "10px" }}>
            <button type="submit" className="boton-verde">
              Agregar
            </button>
            <Link className="boton-azul" to={`/sub-categoria/${id}`}>
              Regresar
            </Link>
          </div>
        </div>
        <Grid container rowSpacing={0} columnSpacing={3}>
          <Grid
            size={{ md: 12, lg: 6 }}
            lg={storage.subcategorias ? 6 : 12}
            className="formulario-inputs"
          >
            <div className="contenedor-2">
              <TextField
                label="Nombre de la sub categoría"
                placeholder="Nombre de la sub categoría"
                onChange={leerDato}
                id="nombre"
                name="nombre"
                variant="standard"
                fullWidth
                required
              />
            </div>
          </Grid>
          {storage.subcategorias && (
            <Grid size={{ md: 12, lg: 6 }}>
              <div className="contenedor-2">
                <p>Imagen</p>
                <div style={{ display: "flex", gap: "15px" }}>
                  <div>
                    <input
                      id="imagen"
                      name="imagen"
                      className="agregar-imagen"
                      placeholder="+"
                      type="file"
                      accept="image/*"
                      onChange={leerImagen}
                    />
                    <label for="imagen">
                      <Add />
                    </label>
                  </div>
                  <img
                    src={imagenes[0]}
                    alt={state.nombre}
                    style={{
                      maxWidth: "calc(90% - 30px)",
                      height: "fit-content",
                    }}
                  />
                </div>
                <p>Icono</p>
                <div
                  style={{ display: "flex", gap: "15px", marginTop: "20px" }}
                >
                  <div>
                    <input
                      id="icono"
                      name="icono"
                      className="agregar-imagen"
                      placeholder="+"
                      type="file"
                      accept="image/*"
                      onChange={leerImagen}
                    />
                    <label for="icono">
                      <Add />
                    </label>
                  </div>
                  <img
                    src={imagenes[1]}
                    alt={state.nombre}
                    style={{
                      maxWidth: "calc(90% - 30px)",
                      height: "fit-content",
                    }}
                  />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default NuevaSubCategoria;
