import React from "react";
import Router from "./Routers/Router";
import { createRoot } from "react-dom/client";

import store from "./Firebase/store";
import { Provider } from "react-redux";
import { GlobalProvider } from "./Context/globalContext";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <GlobalProvider>
      <Router />
    </GlobalProvider>
  </Provider>
);
