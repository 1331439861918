import React, { useState } from "react";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import useDatabase from "../../Firebase/Hooks/useDatabase.js";

// iconos
import { Add } from "@mui/icons-material";

// configuración de la vista
import { storage } from "../../config.js";

const NuevoServicio = () => {
  const [consulta, setConsulta] = useState({ consulta: "" });

  const [imagenes, setImagenes] = useState([]);
  const [filesData, setFiles] = useState([]);

  const [state, setState] = useState({
    nombre: "",
    informacionPrincipal: "",
    informacionSecundaria: "",
  });

  useDatabase(consulta);

  const agregarServicio = async (e) => {
    e.preventDefault();

    setConsulta({
      consulta: "agregarDocumento",
      collectionName: "servicios",
      state,
      files: filesData,
      urlRedirect: "/servicios",
    });
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const leerImagen = (e) => {
    let imagenesPrecarga = [...imagenes];
    let files = [...filesData];
    let indexGlobal = parseInt(e.target.name);

    files[indexGlobal] = e.target.files[0];

    setFiles(files);

    let fileReader = new FileReader();
    fileReader.addEventListener("load", function (evt) {
      imagenesPrecarga[indexGlobal] = fileReader.result;

      setImagenes(imagenesPrecarga);
    });

    fileReader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="row contenedor-1">
      <form onSubmit={agregarServicio}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="titulo-1">Añadir nuevo servicio</p>

          <div style={{ display: "flex", gap: "10px" }}>
            <button type="submit" className="boton-verde">
              Agregar
            </button>
            <Link className="boton-azul" to="/servicios">
              Regresar
            </Link>
          </div>
        </div>
        <Grid container rowSpacing={0} columnSpacing={3}>
          <Grid
            size={{ md: 12 }}
            lg={storage.categorias ? 6 : 12}
            className="formulario-inputs"
          >
            <div className="contenedor-2">
              <TextField
                label="Nombre del servicio"
                placeholder="Nombre del servicio"
                onChange={leerDato}
                id="nombre"
                name="nombre"
                variant="standard"
                fullWidth
                required
              />

              <div>
                <p style={{ marginBottom: "0" }}>Información principal:</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={state.informacionPrincipal}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setState({
                      ...state,
                      informacionPrincipal: data,
                    });
                  }}
                />
              </div>

              <div>
                <p style={{ marginBottom: "0" }}>Información secundaria:</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={state.informacionSecundaria}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setState({
                      ...state,
                      informacionSecundaria: data,
                    });
                  }}
                />
              </div>
            </div>
          </Grid>
          {storage.categorias && (
            <Grid size={{ md: 12, lg: 6 }}>
              <div className="contenedor-2">
                <div>
                  <p>Image exterior escritorio</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>
                      <input
                        id="0"
                        name="0"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagen}
                      />
                      <label for="0">
                        <Add />
                      </label>
                    </div>
                    <img
                      src={imagenes[0]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "fit-content",
                      }}
                    />
                  </div>
                </div>

                <div>
                  <p>Image exterior móvil</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>
                      <input
                        id="1"
                        name="1"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagen}
                      />
                      <label for="1">
                        <Add />
                      </label>
                    </div>
                    <img
                      src={imagenes[1]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "fit-content",
                      }}
                    />
                  </div>
                </div>

                <div>
                  <p>Image principal escritorio</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>
                      <input
                        id="2"
                        name="2"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagen}
                      />
                      <label for="2">
                        <Add />
                      </label>
                    </div>
                    <img
                      src={imagenes[2]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "fit-content",
                      }}
                    />
                  </div>
                </div>

                <div>
                  <p>Image principal móvil</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>
                      <input
                        id="3"
                        name="3"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagen}
                      />
                      <label for="3">
                        <Add />
                      </label>
                    </div>
                    <img
                      src={imagenes[3]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "fit-content",
                      }}
                    />
                  </div>
                </div>

                <div>
                  <p>Image contenido 1</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>
                      <input
                        id="4"
                        name="4"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagen}
                      />
                      <label for="4">
                        <Add />
                      </label>
                    </div>
                    <img
                      src={imagenes[4]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "fit-content",
                      }}
                    />
                  </div>
                </div>

                <div>
                  <p>Image contenido 2</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>
                      <input
                        id="5"
                        name="5"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagen}
                      />
                      <label for="5">
                        <Add />
                      </label>
                    </div>
                    <img
                      src={imagenes[5]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "fit-content",
                      }}
                    />
                  </div>
                </div>

                <div>
                  <p>Image contenido 3</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>
                      <input
                        id="6"
                        name="6"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagen}
                      />
                      <label for="6">
                        <Add />
                      </label>
                    </div>
                    <img
                      src={imagenes[6]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "fit-content",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default NuevoServicio;
