import React, { useState } from "react";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";

import useDatabase from "../../Firebase/Hooks/useDatabase.js";

// iconos
import { Add } from "@mui/icons-material";

// configuración de la vista
import { storage } from "../../config.js";

const NuevaPromocion = () => {
  const [consulta, setConsulta] = useState("");
  useDatabase(consulta);

  const [state, setState] = useState({
    tipoDescuento: "porcentaje",
    descuento: "",
    titulo: "",
    color: "#ff0000",
  });

  const [imagenes, setImagenes] = useState([]);
  const [filesData, setFiles] = useState([]);

  const agregarPromocion = async (e) => {
    e.preventDefault();

    setConsulta({
      consulta: "agregarDocumento",
      collectionName: "promociones",
      state,
      files: filesData,
      urlRedirect: "/promociones",
    });
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const leerSwitch = (e) => {
    if (e.target.value === "porcentaje") {
      setState({
        ...state,
        [e.target.name]: "moneda",
      });
    } else {
      setState({
        ...state,
        [e.target.name]: "porcentaje",
      });
    }
  };

  const leerImagen = (e) => {
    let imagenesPrecarga = [...imagenes];
    let files = [...filesData];
    let indexGlobal = 0;

    if (e.target.name === "icono") {
      indexGlobal = 1;
    }

    files[indexGlobal] = e.target.files[0];

    setFiles(files);

    let fileReader = new FileReader();
    fileReader.addEventListener("load", function (evt) {
      imagenesPrecarga[indexGlobal] = fileReader.result;

      setImagenes(imagenesPrecarga);
    });

    fileReader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="row contenedor-1">
      <form onSubmit={agregarPromocion}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="titulo-1">Añadir nueva promocion</p>

          <div style={{ display: "flex", gap: "10px" }}>
            <button type="submit" className="boton-verde">
              Agregar
            </button>
            <Link className="boton-azul" to="/promociones">
              Regresar
            </Link>
          </div>
        </div>
        <Grid container rowSpacing={0} columnSpacing={3}>
          <Grid
            size={{ md: 12 }}
            lg={storage.promociones ? 6 : 12}
            className="formulario-inputs"
          >
            <div className="contenedor-2">
              <TextField
                label="Titulo de la promoción"
                placeholder="Titulo de la promoción"
                onChange={leerDato}
                id="titulo"
                name="titulo"
                variant="standard"
                fullWidth
                required
              />

              <div>
                <p style={{ marginBottom: "0" }}>
                  ¿Qué tipo de descuento tiene?
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <p style={{ margin: "0" }}>Porcentaje</p>
                  <Switch
                    id="tipoDescuento"
                    name="tipoDescuento"
                    value={state.tipoDescuento}
                    onChange={leerSwitch}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <p style={{ margin: "0" }}>Moneda</p>
                </div>
              </div>

              <TextField
                label="Porcentaje de la promoción"
                placeholder="Porcentaje de la promoción"
                onChange={leerDato}
                id="descuento"
                name="descuento"
                variant="standard"
                fullWidth
                required
              />

              <div>
                <p style={{ marginBottom: "0" }}>Seleccione color de fondo</p>
                <input
                  type="color"
                  id="color"
                  name="color"
                  value={state.color}
                  onChange={leerDato}
                />
              </div>
            </div>
          </Grid>
          {storage.promociones && (
            <Grid size={{ md: 12, lg: 6 }}>
              <div className="contenedor-2">
                <p>Imagen</p>
                <div style={{ display: "flex", gap: "15px" }}>
                  <div>
                    <input
                      id="imagen"
                      name="imagen"
                      className="agregar-imagen"
                      placeholder="+"
                      type="file"
                      accept="image/*"
                      onChange={leerImagen}
                    />
                    <label for="imagen">
                      <Add />
                    </label>
                  </div>
                  <div
                    style={{
                      minWidth: "20px",
                      minHeight: "20px",
                      maxWidth: "calc(90% - 60px)",
                      background: state.color + "80",
                    }}
                  >
                    <img
                      src={imagenes[0]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "100%",
                        height: "fit-content",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default NuevaPromocion;
