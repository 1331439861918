import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CryptoJS from "react-native-crypto-js";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid2";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import useDatabase from "../../Firebase/Hooks/useDatabase.js";

const EditarUsuario = () => {
  const { id } = useParams();

  const [consulta, setConsulta] = useState({
    consulta: "obtenerDocumento",
    collectionName: "usuarios",
    id,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState(false);
  const [passwordDecrypt, setPasswordDecrypt] = useState("");

  const { document } = useSelector((state) => state.firestore);

  useDatabase(consulta);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const editarUsuario = (e) => {
    e.preventDefault();

    let ciphertext = CryptoJS.AES.encrypt(
      passwordDecrypt,
      "Y2Fhdg=="
    ).toString();
    state.password = ciphertext;

    setConsulta({
      consulta: "actualizarDocumento",
      collectionName: "usuarios",
      state,
      id,
      urlRedirect: "/usuarios",
    });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 10);
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "password") setPasswordDecrypt(e.target.value);
  };

  useEffect(() => {
    if (document.password) {
      let bytes = CryptoJS.AES.decrypt(document.password, "Y2Fhdg==");
      let originalText = bytes.toString(CryptoJS.enc.Utf8);

      setPasswordDecrypt(originalText);
      setState({
        ...document,
        password: originalText,
      });
    }
  }, [document]);

  return (
    <div className="row contenedor-1">
      {state && (
        <form onSubmit={editarUsuario}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="titulo-1">Modificar usuario</p>

            <div style={{ display: "flex", gap: "10px" }}>
              <button type="submit" className="boton-verde">
                Editar
              </button>
              <Link className="boton-azul" to="/usuarios">
                Regresar
              </Link>
            </div>
          </div>
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid size={{ md: 12 }} className="formulario-inputs">
              <div className="contenedor-2">
                <TextField
                  label="Nombre de usuario"
                  placeholder="Nombre de usuario"
                  onChange={leerDato}
                  value={state.nombre}
                  id="nombre"
                  name="nombre"
                  variant="standard"
                  fullWidth
                  required
                />
                <TextField
                  label="Correo"
                  placeholder="admin@admin.com"
                  onChange={leerDato}
                  value={state.email}
                  id="email"
                  name="email"
                  type="email"
                  variant="standard"
                  fullWidth
                  required
                />
                <FormControl variant="standard" fullWidth required>
                  <InputLabel htmlFor="user-password">Contraseña</InputLabel>
                  <Input
                    id="user-password"
                    onChange={leerDato}
                    value={passwordDecrypt}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl style={{ marginTop: "10px" }}>
                  <FormLabel id="tipo_usuario">Tipo de usuario:</FormLabel>
                  <RadioGroup
                    aria-labelledby="tipo_usuario"
                    defaultValue="administrador"
                    name="tipo"
                    required
                  >
                    <FormControlLabel
                      value="administrador"
                      control={<Radio />}
                      label="Administrador"
                    />
                    {/* <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default EditarUsuario;
