import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactDOM from "react-dom";

import firebase from "../../Firebase/firebase.js";
import { doc } from "firebase/firestore";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Switch from "@mui/material/Switch";

import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
// select input
import { Checkbox, ListItemText } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// iconos
import { Add } from "@mui/icons-material";

// configuracion de la vista
import { storage, funtion } from "../../config";

import useDatabase from "../../Firebase/Hooks/useDatabase.js";

const NuevoProducto = () => {
  const { id } = useParams();
  const db = firebase.firestore;

  const {
    collection: { materiales },
  } = useSelector((state) => state.firestore);

  const [consulta, setConsulta] = useState({
    consulta: "obtenerRealTime",
    collectionName: "materiales",
    condicionalOrderBy: ["nombre", "asc"],
  });
  useDatabase(consulta);

  const [state, setState] = useState({
    nombre: "",
    id_sub_categoria: id,
    decripcion: "",
    mensajeWhats: "",
    precioMenudeo: 0,
    precioMayoreo: 0,
    descuento: false,
    porcentajeDescuento: 0,
    masVendido: false,
  });

  const [imagenes, setImagenes] = useState([]);
  const [files, setFiles] = useState(false);

  const agregarProducto = (e) => {
    e.preventDefault();

    let copiaState = { ...state };

    state.id_materiales.map((materia, index) => {
      const referencia = doc(db, "materiales", materia);
      return (copiaState.id_materiales[index] = referencia);
    });

    setConsulta({
      consulta: "agregarDocumento",
      collectionName: "productos",
      state: copiaState,
      files,
      urlRedirect: `/productos/${id}`,
    });
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const leerSwitch = (e) => {
    setState({
      ...state,
      [e.target.name]: !state[e.target.name],
    });
  };

  const leerImagen = async (e) => {
    setFiles(e.target.files);
    let imagenesPrecarga = [];

    await new Promise((resolve, reject) => {
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];

        let fileReader = new FileReader();
        fileReader.addEventListener("load", function (evt) {
          imagenesPrecarga.push(fileReader.result);

          if (index === e.target.files.length - 1) {
            resolve(true);
          }
        });

        fileReader.readAsDataURL(file);
      }
    });

    setImagenes(imagenesPrecarga);
  };

  const verImagen = (imagenPrevia) => {
    let contenedor = document.getElementById("fondo-imagen");
    contenedor.className = "fondo-imagen";

    const imagen = <img src={imagenPrevia} alt="Vista previa de imagen" />;

    ReactDOM.render(imagen, contenedor);
  };

  // selector de materiales
  const [material, setMateriales] = useState([]);

  const seleccionarMaterial = (e) => {
    setState({
      ...state,
      id_materiales: e.target.value,
    });

    setMateriales(e.target.value);
  };
  // fin - selector de materiales

  return (
    <div className="row contenedor-1">
      <form onSubmit={agregarProducto}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="titulo-1">Añadir nuevo producto</p>

          <div style={{ display: "flex", gap: "10px" }}>
            <button type="submit" className="boton-verde">
              Agregar
            </button>
            <Link className="boton-azul" to={`/productos/${id}`}>
              Regresar
            </Link>
          </div>
        </div>

        <Grid container rowSpacing={0} columnSpacing={3}>
          <Grid
            size={{ md: 12 }}
            lg={storage.productos ? 6 : 12}
            className="formulario-inputs"
          >
            <div className="contenedor-2">
              <TextField
                label="Nombre del producto"
                placeholder="Nombre del producto"
                onChange={leerDato}
                id="nombre"
                name="nombre"
                variant="standard"
                fullWidth
                required
              />
              <TextField
                label="Mensaje de WhatsApp"
                placeholder="Mensaje de WhatsApp"
                onChange={leerDato}
                id="mensajeWhats"
                name="mensajeWhats"
                variant="standard"
                fullWidth
                required
              />
              <TextField
                label="Precio menudeo del producto"
                placeholder="Precio menudeo del producto"
                onChange={leerDato}
                id="precioMenudeo"
                name="precioMenudeo"
                variant="standard"
                fullWidth
                required
              />
              <TextField
                label="Precio mayoreo del producto"
                placeholder="Precio mayoreo del producto"
                onChange={leerDato}
                id="precioMayoreo"
                name="precioMayoreo"
                variant="standard"
                fullWidth
                required
              />

              <div>
                <p style={{ marginBottom: "0" }}>Descripción:</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={state.decripcion}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setState({
                      ...state,
                      decripcion: data,
                    });
                  }}
                />
              </div>

              {funtion.materiales && (
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Materiales
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    multiple
                    value={material}
                    onChange={seleccionarMaterial}
                  >
                    <MenuItem value="">
                      <em>Seleccione una material</em>
                    </MenuItem>
                    {materiales?.docs.map((materialData) => (
                      <MenuItem key={materialData.id} value={materialData.id}>
                        <Checkbox
                          checked={material.indexOf(materialData.id) > -1}
                        />
                        <ListItemText primary={materialData.nombre} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <div>
                <p style={{ marginBottom: "0" }}>
                  ¿El producto tiene descuento?
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <p style={{ margin: "0" }}>No</p>
                  <Switch
                    id="descuento"
                    name="descuento"
                    onChange={leerSwitch}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <p style={{ margin: "0" }}>Sí</p>
                </div>
                {state.descuento && (
                  <div>
                    <TextField
                      label="Porcentaje del descuento"
                      placeholder="Porcentaje del descuento"
                      onChange={leerDato}
                      id="porcentajeDescuento"
                      name="porcentajeDescuento"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </div>
                )}
              </div>

              <div>
                <p style={{ marginBottom: "0" }}>
                  ¿El producto tiene etiqueta de mas vendido?
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <p style={{ margin: "0" }}>No</p>
                  <Switch
                    id="masVendido"
                    name="masVendido"
                    onChange={leerSwitch}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <p style={{ margin: "0" }}>Sí</p>
                </div>
              </div>
            </div>
          </Grid>
          {storage.productos && (
            <Grid size={{ md: 12, lg: 6 }}>
              <div className="contenedor-2">
                <div
                  style={{ display: "flex", gap: "15px", marginBottom: "15px" }}
                >
                  <input
                    id="imagen"
                    name="imagen"
                    className="agregar-imagen"
                    placeholder="+"
                    type="file"
                    accept="image/*"
                    onChange={leerImagen}
                    multiple
                  />
                  <label for="imagen">
                    <Add />
                  </label>
                </div>
                <div className="galeria-imagenes">
                  {imagenes.map((imagen) => (
                    <img
                      onClick={() => {
                        verImagen(imagen);
                      }}
                      src={imagen}
                      alt={state.clienta}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "auto",
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default NuevoProducto;
