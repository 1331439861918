import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { globalContext } from "../../Context/globalContext.js";
import useDatabase from "../../Firebase/Hooks/useDatabase.js";

import Tabla from "../../Components/Tabala.js";

const headCells = [
  {
    id: "nombre",
    numeric: false,
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "acciones",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
];

const Materiales = () => {
  const { setTitle } = useContext(globalContext);
  setTitle("Materiales");

  const {
    collection: { materiales },
  } = useSelector((state) => state.firestore);

  useDatabase({ consulta: "obtenerRealTime", collectionName: "materiales" });

  return (
    <div name="collection" className="contenedor-1">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="titulo-1">Todas los materiales</p>
        <Link className="boton-morado" to="/nuevo-material">
          Nuevo Material
        </Link>
      </div>

      {materiales && (
        <div style={{ marginBottom: "80px" }}>
          <Tabla
            collectionData={{
              collection: materiales.docs,
              collectionName: "materiales",
              headCells,
              eliminar: true,
              editar: true,
              urlPrincipal: "material",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Materiales;
