import React, { useState } from "react";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";

import useDatabase from "../../Firebase/Hooks/useDatabase.js";

// iconos
import { Add } from "@mui/icons-material";

// configuracion de la vista
import { storage } from "../../config.js";

const NuevoMaterial = () => {
  const [consulta, setConsulta] = useState({ consulta: "" });

  const [imagen, setImagen] = useState("");
  const [file, setFile] = useState(false);

  const [state, setState] = useState({
    nombre: "",
  });

  useDatabase(consulta);

  const agregarMaterial = async (e) => {
    e.preventDefault();

    setConsulta({
      consulta: "agregarDocumento",
      collectionName: "materiales",
      state,
      file,
    });
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const leerImagen = (e) => {
    setFile(e.target.files[0]);

    let fileReader = new FileReader();
    fileReader.addEventListener("load", function (evt) {
      setImagen(fileReader.result);
    });

    fileReader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="row contenedor-1">
      <form onSubmit={agregarMaterial}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="titulo-1">Añadir nuevo material</p>

          <div style={{ display: "flex", gap: "10px" }}>
            <button type="submit" className="boton-verde">
              Agregar
            </button>
            <Link className="boton-azul" to="/materiales">
              Regresar
            </Link>
          </div>
        </div>
        <Grid container rowSpacing={0} columnSpacing={3}>
          <Grid
            size={{ md: 12 }}
            lg={storage.marcas ? 6 : 12}
            className="formulario-inputs"
          >
            <div className="contenedor-2">
              <TextField
                label="Nombre del material"
                placeholder="Nombre del material"
                onChange={leerDato}
                id="nombre"
                name="nombre"
                variant="standard"
                fullWidth
                required
              />
            </div>
          </Grid>
          {storage.marcas && (
            <Grid size={{ md: 12, lg: 6 }}>
              <div
                className="contenedor-2"
                style={{ display: "flex", gap: "15px" }}
              >
                <div>
                  <input
                    id="imagen"
                    name="imagen"
                    className="agregar-imagen"
                    placeholder="+"
                    type="file"
                    accept="image/*"
                    onChange={leerImagen}
                  />
                  <label for="imagen">
                    <Add />
                  </label>
                </div>
                <img
                  src={imagen}
                  alt={state.nombre}
                  style={{
                    maxWidth: "calc(90% - 30px)",
                    height: "fit-content",
                  }}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default NuevoMaterial;
