import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { globalContext } from "../../Context/globalContext";
import useConsult from "../../Firebase/Hooks/useConsult.js";

import Tabla from "../../Components/Tabala";

const headCells = [
  {
    id: "nombre",
    numeric: false,
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "acciones",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
];

const SubCategorias = () => {
  const { setTitle } = useContext(globalContext);

  const { id } = useParams();

  const {
    collection: { subCategorias },
    document,
  } = useSelector((state) => state.firestore);

  useConsult([
    {
      consulta: "obtenerRealTime",
      collectionName: "subCategorias",
      condicionWhere: ["categoria_id", "==", id],
    },
    { consulta: "obtenerDocumento", collectionName: "categorias", id },
  ]);

  setTitle(`Sub Categorias de ${document.nombre}`);

  return (
    <div name="collection" className="contenedor-1">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="titulo-1">Todas las sub categorias</p>
        <Link className="boton-morado" to={`/nueva-sub-categoria/${id}`}>
          Nueva Sub Categoria
        </Link>
      </div>

      {subCategorias && (
        <div style={{ marginBottom: "80px" }}>
          <Tabla
            collectionData={{
              collection: subCategorias.docs,
              collectionName: "subCategorias",
              headCells,
              eliminar: true,
              editar: true,
              verInterno: true,
              urlPrincipal: `sub-categoria/${id}`,
              urlInterno: "productos",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SubCategorias;
