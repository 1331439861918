import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";

// iconos
import { Add } from "@mui/icons-material";

import useDatabase from "../../Firebase/Hooks/useDatabase.js";

// configuracion de la vista
import { storage } from "../../config.js";

const EditarMarca = () => {
  const { id } = useParams();
  const { document } = useSelector((state) => state.firestore);

  const [consulta, setConsulta] = useState({
    consulta: "obtenerDocumento",
    collectionName: "marcas",
    id,
  });
  const [state, setState] = useState(false);

  const [imagen, setImagen] = useState("");
  const [file, setFile] = useState(false);

  useDatabase(consulta);

  const editarMarca = (e) => {
    e.preventDefault();
    setConsulta({ consulta: "" });

    setTimeout(() => {
      setConsulta({
        consulta: "actualizarDocumento",
        collectionName: "marcas",
        state,
        file,
        id,
        urlRedirect: "/marcas",
      });
    }, 10);
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const leerImagen = (e) => {
    setFile(e.target.files[0]);

    let fileReader = new FileReader();
    fileReader.addEventListener("load", function (evt) {
      setImagen(fileReader.result);
    });

    fileReader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (document) {
      setState({
        ...document,
      });
      setImagen(document.url);
    }
  }, [document]);

  return (
    <div className="row contenedor-1">
      {state && (
        <form onSubmit={editarMarca}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="titulo-1">Modificar marca</p>

            <div style={{ display: "flex", gap: "10px" }}>
              <button type="submit" className="boton-verde">
                Editar
              </button>
              <Link className="boton-azul" to="/marcas">
                Regresar
              </Link>
            </div>
          </div>
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid
              size={{ md: 12 }}
              lg={storage.marcas ? 6 : 12}
              className="formulario-inputs"
            >
              <div className="contenedor-2">
                <TextField
                  label="Nombre de la marca"
                  placeholder="Nombre de la marca"
                  onChange={leerDato}
                  value={state.nombre}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="nombre"
                  name="nombre"
                  variant="standard"
                  fullWidth
                  required
                />
              </div>
            </Grid>
            {storage.marcas && (
              <Grid size={{ md: 12, lg: 6 }}>
                <div
                  className="contenedor-2"
                  style={{ display: "flex", gap: "15px" }}
                >
                  <div>
                    <input
                      id="imagen"
                      name="imagen"
                      className="agregar-imagen"
                      placeholder="+"
                      type="file"
                      accept="image/*"
                      onChange={leerImagen}
                    />
                    <label for="imagen">
                      <Add />
                    </label>
                  </div>
                  <img
                    src={imagen}
                    alt={state.nombre}
                    style={{
                      maxWidth: "calc(90% - 30px)",
                      height: "fit-content",
                    }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </div>
  );
};

export default EditarMarca;
