import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import CryptoJS from "react-native-crypto-js";

import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Firebase/Hooks/useDatabase.js";

const NuevoUsuario = () => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Nuevo Usuario");

  const [showPassword, setShowPassword] = useState(false);
  const [consulta, setConsulta] = useState({ consulta: "" });
  const [state, setState] = useState({
    nombre: "",
    email: "",
    password: "",
    tipo: 0,
  });
  useDatabase(consulta);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const agregarUsuario = (e) => {
    e.preventDefault();

    let ciphertext = CryptoJS.AES.encrypt(
      state.password,
      "Y2Fhdg=="
    ).toString();
    state.password = ciphertext;

    setConsulta({
      consulta: "agregarDocumento",
      collectionName: "usuarios",
      state,
      urlRedirect: "/usuarios",
    });
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="row contenedor-1">
      <form onSubmit={agregarUsuario}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="titulo-1">Añadir nuevo usuario</p>

          <div style={{ display: "flex", gap: "10px" }}>
            <button type="submit" className="boton-verde">
              Agregar
            </button>
            <Link className="boton-azul" to="/usuarios">
              Regresar
            </Link>
          </div>
        </div>
        <Grid container rowSpacing={0} columnSpacing={3}>
          <Grid size={12} className="formulario-inputs">
            <div className="contenedor-2">
              <TextField
                label="Nombre de usuario"
                placeholder="Nombre de usuario"
                onChange={leerDato}
                id="nombre"
                name="nombre"
                variant="standard"
                fullWidth
                required
              />
              <TextField
                label="Correo"
                placeholder="admin@admin.com"
                onChange={leerDato}
                id="email"
                name="email"
                type="email"
                variant="standard"
                fullWidth
                required
              />
              <FormControl variant="standard" fullWidth required>
                <InputLabel htmlFor="user-password">Contraseña</InputLabel>
                <Input
                  id="user-password"
                  onChange={leerDato}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl style={{ marginTop: "10px" }}>
                <FormLabel id="tipo_usuario">Tipo de usuario:</FormLabel>
                <RadioGroup
                  aria-labelledby="tipo_usuario"
                  defaultValue="administrador"
                  name="tipo"
                  required
                >
                  <FormControlLabel
                    value="administrador"
                    control={<Radio />}
                    label="Administrador"
                  />
                  {/* <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                </RadioGroup>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default NuevoUsuario;
