import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";

// iconos
import { Add } from "@mui/icons-material";

import useDatabase from "../../Firebase/Hooks/useDatabase.js";

// configuracion de la vista
import { storage } from "../../config.js";

const EditarCategoria = () => {
  const { id } = useParams();
  const { document } = useSelector((state) => state.firestore);

  const [consulta, setConsulta] = useState({
    consulta: "obtenerDocumento",
    collectionName: "categorias",
    id,
  });
  const [state, setState] = useState(false);

  const [imagenes, setImagenes] = useState([]);
  const [filesData, setFiles] = useState([]);

  useDatabase(consulta);

  const editarCategoria = (e) => {
    e.preventDefault();
    setConsulta({ consulta: "" });

    setTimeout(() => {
      setConsulta({
        consulta: "actualizarDocumento",
        collectionName: "categorias",
        state,
        files: filesData,
        id,
        urlRedirect: "/categorias",
      });
    }, 10);
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const leerImagen = (e) => {
    let imagenesPrecarga = [...imagenes];
    let files = [...filesData];
    let indexGlobal = 0;

    if (e.target.name === "icono") {
      indexGlobal = 1;
    }

    files[indexGlobal] = e.target.files[0];

    setFiles(files);

    let fileReader = new FileReader();
    fileReader.addEventListener("load", function (evt) {
      imagenesPrecarga[indexGlobal] = fileReader.result;

      setImagenes(imagenesPrecarga);
    });

    fileReader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (document) {
      setState({
        ...document,
      });
      setImagenes(document.imagenes.imagenes);
    }
  }, [document]);

  return (
    <div className="row contenedor-1">
      {state && (
        <form onSubmit={editarCategoria}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="titulo-1">Modificar categoria</p>

            <div style={{ display: "flex", gap: "10px" }}>
              <button type="submit" className="boton-verde">
                Editar
              </button>
              <Link className="boton-azul" to="/categorias">
                Regresar
              </Link>
            </div>
          </div>
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid
              size={{ md: 12 }}
              md={12}
              lg={storage.categorias ? 6 : 12}
              className="formulario-inputs"
            >
              <div className="contenedor-2">
                <TextField
                  label="Nombre de la categoria"
                  placeholder="Nombre de la categoria"
                  onChange={leerDato}
                  value={state.nombre}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="nombre"
                  name="nombre"
                  variant="standard"
                  fullWidth
                  required
                />
              </div>
            </Grid>
            {storage.categorias && (
              <Grid size={{ md: 12, lg: 6 }}>
                <div className="contenedor-2">
                  <p>Imagen</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>
                      <input
                        id="imagen"
                        name="imagen"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagen}
                      />
                      <label for="imagen">
                        <Add />
                      </label>
                    </div>
                    <img
                      src={imagenes[0]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "fit-content",
                      }}
                    />
                  </div>
                  <p>Icono</p>
                  <div
                    style={{ display: "flex", gap: "15px", marginTop: "20px" }}
                  >
                    <div>
                      <input
                        id="icono"
                        name="icono"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagen}
                      />
                      <label for="icono">
                        <Add />
                      </label>
                    </div>
                    <img
                      src={imagenes[1]}
                      alt={state.nombre}
                      style={{
                        maxWidth: "calc(90% - 30px)",
                        height: "fit-content",
                      }}
                    />
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </div>
  );
};

export default EditarCategoria;
