import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { esES } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";

import useDatabase from "../Firebase/Hooks/useDatabase.js";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
    return -1;
  }
  if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -1 * descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "acciones" ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const theme = createTheme(esES);

const Tabla = ({ collectionData }) => {
  const {
    collection,
    collectionName,
    headCells,
    eliminar = false,
    editar = false,
    verPrincipal = false,
    urlPrincipal = "",
    verInterno = false,
    urlInterno = "",
  } = collectionData;

  const [flag, setFlag] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [consulta, setConsulta] = useState({ consulta: "" });
  const [result] = useDatabase(consulta);

  useEffect(() => {
    if (result) {
      setConsulta({ consulta: "resetConsult" });
    }
  }, [result]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setFlag(false);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    setTimeout(() => {
      setFlag(true);
    }, 10);
  };

  const handleChangePage = (event, newPage) => {
    setFlag(false);

    setPage(newPage);

    setTimeout(() => {
      setFlag(true);
    }, 10);
  };

  const handleChangeRowsPerPage = (event) => {
    setFlag(false);

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    setTimeout(() => {
      setFlag(true);
    }, 10);
  };

  const deleteData = (state) => {
    setConsulta({
      consulta: "eliminarDocumento",
      collectionName,
      state,
    });
  };

  // Avoid a layout jump when reaching the last page with empty collectionData.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - collection.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={collection.length}
              headCells={headCells}
            />
            <TableBody>
              {!flag ? (
                <h4>cargando...</h4>
              ) : (
                stableSort(collection, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((document) => (
                    <TableRow hover tabIndex={-1} key={document.id}>
                      {/* ciclamos los parametrso que debe de extraer de la base de datso y pintar en la tabla */}
                      {headCells.map(
                        (dato) =>
                          // evaluamos que el parametro que se quiere mostrar exista y que no sea el estado
                          document[dato.id] &&
                          dato.id !== "atendido" && (
                            <TableCell align="left">
                              {document[dato.id]}
                            </TableCell>
                          )
                      )}
                      {/* verificamos que se pasara el parametro de estado del archivo */}
                      {headCells.find((cell) => cell.id === "estado") && (
                        <TableCell align="left">
                          {document.atendido ? (
                            <div className="atendido">
                              <p>Atendido</p>
                            </div>
                          ) : (
                            <div className="pendiente">
                              <p>Pendiente</p>
                            </div>
                          )}
                        </TableCell>
                      )}
                      {/* por defecto todas las tablas tienen acciones */}
                      <TableCell align="left">
                        <div style={{ display: "flex", gap: "10px" }}>
                          {verInterno && (
                            <Link
                              className="boton-verde"
                              to={`/${urlInterno}/${document.id}`}
                            >
                              Ver
                            </Link>
                          )}
                          {verPrincipal && (
                            <Link
                              className="boton-verde"
                              to={`/ver-${urlPrincipal}/${document.id}`}
                            >
                              Ver
                            </Link>
                          )}
                          {editar && (
                            <Link
                              className="boton-azul"
                              to={`/editar-${urlPrincipal}/${document.id}`}
                            >
                              Editar
                            </Link>
                          )}
                          {eliminar && (
                            <button
                              onClick={() => {
                                deleteData(document);
                              }}
                              className="boton-rojo"
                            >
                              Eliminar
                            </button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <ThemeProvider theme={theme}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={collection.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Filas por página"}
          />
        </ThemeProvider>
      </Paper>
    </Box>
  );
};

export default Tabla;
