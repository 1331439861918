import React, { Fragment, useContext } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate, Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid2";

import { globalContext } from "../Context/globalContext";

import userImage from "../Images/user_icon.png";

import Loader from "./Loader/Loader";

// icons
import {
  Person,
  Email,
  Category,
  Logout,
  Discount,
  Percent,
  DesignServices,
} from "@mui/icons-material";

const SideNav = () => {
  const history = useNavigate();
  const global = useContext(globalContext);
  let user = global.userGlobal;
  let title = global.title;
  let loader = global.loader;

  const cerrarSesion = () => {
    sessionStorage.clear();
    Swal.fire({
      title: "Sesión Cerrada",
      text: "Sesión fue cerrada correctamente",
      confirmButtonColor: "#2DCD22",
      confirmButtonText: "Continuar",
      icon: "success",
    }).then(() => {
      history("/");
    });
  };

  // para cerrar el poppop
  const cerrarImagen = () => {
    let contenedor = document.getElementById("fondo-imagen");
    contenedor.className = "fondo-imagen-off";
  };

  if (window.location.pathname !== "/")
    return (
      <Fragment>
        {/* funcion para ver imagenes en pantalla completa */}
        <div
          onClick={cerrarImagen}
          className="fondo-imagen-off"
          id="fondo-imagen"
        ></div>

        {!loader && <Loader />}
        <div className="sidenav-custom">
          <div className="sidenav-user">
            <img src={userImage} alt="Imagen Usuario Administrador" />
            <div>
              <p>{user.nombre}</p>
              <p>{user.email}</p>
            </div>
          </div>
          <div className="sidenav-title">
            <p>{title}</p>
          </div>
        </div>
        <Grid
          container
          spacing={0}
          style={{ height: "calc(100vh - 80px)", marginTop: "auto" }}
        >
          <Grid size={3} style={{ padding: "0px 20px 50px 20px" }}>
            <div
              style={{
                padding: "20px 0 20px 20px",
                width: "100%",
                background: "#ffffff",
              }}
            >
              <Link to="/usuarios">
                <p className="sidenav-opcion">
                  <Person /> Usuarios
                </p>
              </Link>
              <Link to="/mensajes">
                <p className="sidenav-opcion">
                  <Email /> Mensaje
                </p>
              </Link>
              <Link to="/categorias">
                <p className="sidenav-opcion">
                  <Category />
                  Categorías
                </p>
              </Link>
              <Link to="/materiales">
                <p className="sidenav-opcion">
                  <Discount />
                  Materiales
                </p>
              </Link>
              <Link to="/promociones">
                <p className="sidenav-opcion">
                  <Percent />
                  Promociones
                </p>
              </Link>
              <Link to="/servicios">
                <p className="sidenav-opcion">
                  <DesignServices />
                  Servicios
                </p>
              </Link>
              <button className="sidenav-opcion" onClick={cerrarSesion}>
                <Logout /> Cerrar sesión
              </button>
            </div>
          </Grid>
          <Grid size={9}>
            <Outlet />
          </Grid>
        </Grid>
      </Fragment>
    );
};

export default SideNav;
