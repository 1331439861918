export const funtion = Object.freeze({
  categorias: true,
  subcategorias: true,
  productos: true,
  mensajes: true,
  materiales: true,
  promociones: true,
  servicios: true,
});

export const storage = Object.freeze({
  categorias: true,
  subcategorias: true,
  productos: true,
  galeria_productos: true,
  materiales: false,
  servicios: false,
});
