import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";

// iconos
import { Add } from "@mui/icons-material";

import useDatabase from "../../Firebase/Hooks/useDatabase.js";

// configuracion de la vista
import { storage } from "../../config";

const EditarSubCategoria = () => {
  const { id, id_sub_categoria } = useParams();

  const [consulta, setConsulta] = useState({
    consulta: "obtenerDocumento",
    collectionName: "subCategorias",
    id,
  });

  const { document } = useSelector((state) => state.firestore);

  const [state, setState] = useState(false);

  const [imagen, setImagen] = useState("");
  const [file, setFile] = useState(false);

  useDatabase(consulta);

  const editarUsuario = (e) => {
    e.preventDefault();

    setConsulta({
      consulta: "actualizarDocumento",
      collectionName: "subCategorias",
      state,
      file,
      id,
      urlRedirect: `/sub-categoria/${id_sub_categoria}`,
    });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 10);
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const leerImagen = (e) => {
    setFile(e.target.files[0]);

    let fileReader = new FileReader();
    fileReader.addEventListener("load", function (evt) {
      setImagen(fileReader.result);
    });

    fileReader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (document.nombre) {
      setState({
        ...document,
      });
      setImagen(document.url);
    }
  }, [document]);

  return (
    <div className="row contenedor-1">
      {state && (
        <form onSubmit={editarUsuario}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="titulo-1">Modificar sub categoria</p>

            <div style={{ display: "flex", gap: "10px" }}>
              <button type="submit" className="boton-verde">
                Editar
              </button>
              <Link
                className="boton-azul"
                to={`/sub-categoria/${id_sub_categoria}`}
              >
                Regresar
              </Link>
            </div>
          </div>
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid
              size={{ md: 12 }}
              lg={storage.subcategorias ? 6 : 12}
              className="formulario-inputs"
            >
              <div className="contenedor-2">
                <TextField
                  label="Nombre de la sub categoria"
                  placeholder="Nombre de la sub categoria"
                  onChange={leerDato}
                  value={state.nombre}
                  id="nombre"
                  name="nombre"
                  variant="standard"
                  fullWidth
                  required
                />
              </div>
            </Grid>
            {storage.subcategorias && (
              <Grid size={{ md: 12, lg: 6 }}>
                <div
                  className="contenedor-2"
                  style={{ display: "flex", gap: "15px" }}
                >
                  <div>
                    <input
                      id="imagen"
                      name="imagen"
                      className="agregar-imagen"
                      placeholder="+"
                      type="file"
                      accept="image/*"
                      onChange={leerImagen}
                    />
                    <label for="imagen">
                      <Add />
                    </label>
                  </div>
                  <img
                    src={imagen}
                    alt={state.nombre}
                    style={{
                      maxWidth: "calc(90% - 30px)",
                      height: "fit-content",
                    }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </div>
  );
};

export default EditarSubCategoria;
