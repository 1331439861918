import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import SideNav from "../Components/SideNav";

// *** administrador ***  //
import Login from "../Views/Login";

// usuarios
import Usuarios from "../Views/Usuarios/Usuarios";
import NuevoUsuario from "../Views/Usuarios/NuevoUsuario";
import EditarUsuario from "../Views/Usuarios/EditarUsuario";

// mensajes
import Mensajes from "../Views/Mensaje/Mensajes";
import Mensaje from "../Views/Mensaje/Mensaje";

// categorias
import Categorias from "../Views/Categorias/Categorias";
import NuevaCategoria from "../Views/Categorias/NuevaCategoria";
import EditarCategoria from "../Views/Categorias/EditarCategoria";

// sub categorias
import SubCategorias from "../Views/SubCategorias/SubCategorias";
import NuevaSubCategoria from "../Views/SubCategorias/NuevaSubCategoria";
import EditarSubCategoria from "../Views/SubCategorias/EditarSubCategoria";

// productos
import Productos from "../Views/Productos/Productos";
import NuevoProducto from "../Views/Productos/NuevoProducto";

// Materiales
import Materiales from "../Views/Materiales/Materiales";
import NuevoMaterial from "../Views/Materiales/NuevoMaterial";
import EditarMaterial from "../Views/Materiales/EditarMaterial";

// promociones
import Promociones from "../Views/Promociones/Promociones";
import NuevaPromocion from "../Views/Promociones/NuevaPromocion";

// servicios
import Servicios from "../Views/Servicios/Servicios";
import NuevoServicio from "../Views/Servicios/NuevoServicio";

const Routers = () => (
  <BrowserRouter>
    <Routes>
      {/* Rutas fuera del SideNav */}
      <Route path="/" element={<Login />} />

      {/* Rutas dentro del SideNav */}
      <Route element={<SideNav />}>
        {/* usuarios */}
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/nuevo-usuario" element={<NuevoUsuario />} />
        <Route path="/editar-usuario/:id" element={<EditarUsuario />} />

        {/* mensajes */}
        <Route path="/mensajes" element={<Mensajes />} />
        <Route path="/ver-mensaje/:id" element={<Mensaje />} />

        {/* Categorias */}
        <Route path="/categorias" element={<Categorias />} />
        <Route path="/nueva-categoria" element={<NuevaCategoria />} />
        <Route path="/editar-categoria/:id" element={<EditarCategoria />} />

        {/* sub categorias */}
        <Route path="/sub-categoria/:id" element={<SubCategorias />} />
        <Route
          path="/nueva-sub-categoria/:id"
          element={<NuevaSubCategoria />}
        />
        <Route
          path="/editar-sub-categoria/:id_sub_categoria/:id"
          element={<EditarSubCategoria />}
        />

        {/* productos */}
        <Route path="/productos/:id" element={<Productos />} />
        <Route path="/nuevo-producto/:id" element={<NuevoProducto />} />

        {/* Materiales */}
        <Route path="/materiales" element={<Materiales />} />
        <Route path="/nuevo-material" element={<NuevoMaterial />} />
        <Route path="/editar-material/:id" element={<EditarMaterial />} />

        {/* promociones */}
        <Route path="/promociones" element={<Promociones />} />
        <Route path="/nueva-promocion" element={<NuevaPromocion />} />

        {/* servicios */}
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/nuevo-servicio" element={<NuevoServicio />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Routers;
