import { useEffect, useState } from 'react'
import useDatabase from './useDatabase'
import { useSelector } from 'react-redux'

/**
 * suo para ciclar las diferentes consultas que hay, los datos que construye el objeto son::
 * @param {Consulta que se va a ejecutar} consulta
 * @param {Coleccion que se va a traer} collectionName
 * @param {Condicional que se va a ejecutar} condicionWhere
 * @param {Cambio de nombere en el redux de la coleccion} storeAs
 * @param {Datos a insertar o actualizar} state
 * @param {Archivo a insertar o actualizar} file
 * @param {Arreglo de archivos a actualizar, insertar} files
 * @param {Identificador del dato} id
 * @param {Redireccion al concluir la consulta} urlRedirect
 * @returns
 */
const useConsult = (consultas) => {
  const [indexConsulta, setIndexConsulta] = useState(0)
  const [datosConsulta, setDatosConsulta] = useState(consultas[indexConsulta])

  const { collection } = useSelector((state) => state.firestore)

  // TODO: cambiar la forma en la que se invocan los hooks y se llaman las consultas de una ...
  // ...  forma asincrona con returns para reducir el numero de reinvocaciones
  // const funcion = () => {
  //   console.log('se ejecuto la funcion')
  //   consultas.forEach((consulta) => {
  //     console.log({ consulta })

  //     console.log({ collection })
  //   })
  // }

  // funcion()

  useDatabase(datosConsulta)

  useEffect(() => {
    if (indexConsulta === 0) {
      setIndexConsulta(1)
    }

    if (
      collection[datosConsulta.collectionName] &&
      indexConsulta < consultas.length
    ) {
      setDatosConsulta(consultas[indexConsulta])

      setIndexConsulta(indexConsulta + 1)
    }
  }, [consultas, collection])
}

export default useConsult
