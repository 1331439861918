import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CryptoJS from "react-native-crypto-js";
import Swal from "sweetalert2";

import user from "../Images/user.svg";
import pass from "../Images/password.svg";

import { globalContext } from "../Context/globalContext";
import { getCollection } from "../Firebase/Redux/Features/Firestore/firestoreSlice";

const Index = () => {
  const dispatch = useDispatch();

  const [passwordType, setPasswordType] = useState("password");
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const userGlobal = useContext(globalContext);

  const password = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const login = async (e) => {
    e.preventDefault();
    const res = await dispatch(
      getCollection({
        collectionName: "usuarios",
        whereCondicion: ["email", "==", state.email],
      })
    );

    if (res.payload.length <= 0) {
      // console.log("error en correo");
      Swal.fire({
        title: "Error",
        text: "Datos de acceso erroneos",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });

      return true;
    }

    let bytes = CryptoJS.AES.decrypt(res.payload[0].password, "Y2Fhdg==");
    let originalText = bytes.toString(CryptoJS.enc.Utf8);

    if (originalText !== state.password) {
      // console.log("error en login password");
      Swal.fire({
        title: "Error",
        text: "Datos de acceso erroneos",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
      return false;
    }

    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(res.payload[0]),
      "jucaba"
    ).toString();

    sessionStorage.setItem("__user_admin__", ciphertext);

    Swal.fire({
      title: "¡Bienvenido!",
      text: "Inicio de sesión exitoso",
      confirmButtonColor: "#2DCD22",
      confirmButtonText: "Continuar",
      icon: "success",
    }).then(() => {
      userGlobal.setUserGlobal(res.payload[0]);
      navigate("/usuarios");
    });
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <form onSubmit={login}>
        <div className="login sombra">
          <p style={{ fontSize: "30px", fontWeight: "bold" }}>
            Inicio de sesión
          </p>
          <div className="login-form">
            <img src={user} alt="icon user" />
            <input
              onChange={leerDato}
              placeholder="E-mail"
              id="email"
              name="email"
              type="email"
              required
            />
          </div>
          <div className="login-form">
            <img onClick={password} src={pass} alt="icon password" />
            <input
              onChange={leerDato}
              placeholder="Password"
              id="password"
              name="password"
              type={passwordType}
              required
            />
          </div>
          <button className="boton-login" type="submit">
            Entrar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Index;
