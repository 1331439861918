import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getDocument,
  getCollection,
  setDocument,
  deleteDocument,
  editDocument,
} from "../Redux/Features/Firestore/firestoreSlice";

import useRealtime from "../Hooks/useRealtime";
import useStorage from "../Hooks/useStorage";

/**
 * se usa para guardar toda la informacion de la consulta, los datos que se usan son:
 * @param {Consulta que se va a ejecutar} consulta
 * @param {Coleccion que se va a traer} collectionName
 * @param {Condicional que se va a ejecutar} condicionWhere
 * @param {Limite de datos que traera la consulta} condicionalLimit
 * @param {Orden en que se ejecuatra la consulta por una key de la coleccion} condicionalOrderBy
 * @param {Cambio de nombere en el redux de la coleccion} storeAs
 * @param {Array de claves de referencias que tiene la coleccion} keyReference
 * @param {Datos a insertar o actualizar} state
 * @param {Archivo a insertar o actualizar} file
 * @param {Arreglo de archivos a actualizar, insertar} files
 * @param {Identificador del dato} id
 * @returns
 */
const useDatabase = ({
  consulta,
  collectionName,
  condicionWhere,
  condicionalLimit,
  condicionalOrderBy,
  storeAs,
  keyReference,
  state,
  file,
  files,
  id,
}) => {
  const { collection } = useSelector((state) => state.firestore);
  const dispatch = useDispatch();
  const [result, setResult] = useState(false);

  // para las consultas en realtime
  const [consultaRealTime, setConsultaRealTime] = useState({
    consulta: "",
  });

  // retorno de las consultas en realtime
  const [realtime] = useRealtime(consultaRealTime);

  // para las consultas en realtime
  const [consultaStorage, setConsultaStorage] = useState({
    consulta: "",
  });

  // retorno de las consultas en realtime
  const [storage] = useStorage(consultaStorage);

  // retorno del estado redux del storage
  const { url } = useSelector((state) => state.storage);

  // obtener documento
  const obtenerDocumento = async () => {
    let res = await dispatch(
      getDocument({
        collectionName: collectionName,
        id: id,
      })
    );

    setResult(res.payload);
  };

  // obtener colleccion
  const obtenerCollecion = async () => {
    if (!collection[collectionName]) {
      let res = await dispatch(
        getCollection({
          collectionName: collectionName,
          whereCondicion: condicionWhere,
        })
      );

      setResult(res.payload.data);
    }
  };

  // obtener colleccion en tiempo real
  const obtenerRealTime = async () => {
    if (!condicionWhere) {
      if (
        !collection[collectionName] ||
        (collection[collectionName].condicionWhere !== condicionWhere &&
          collection[collectionName].condicionWhere !== "false")
      ) {
        setConsultaRealTime({
          consulta: "obtenerRealTime",
          collectionName,
          condicionWhere,
          condicionalLimit,
          condicionalOrderBy,
          storeAs,
          keyReference,
        });
      }
    } else {
      if (
        !collection[collectionName] ||
        (collection[collectionName].condicionWhere !==
          condicionWhere.toString() &&
          collection[collectionName].condicionWhere !== "false")
      ) {
        setConsultaRealTime({
          consulta: "obtenerRealTime",
          collectionName,
          condicionWhere,
          condicionalLimit,
          condicionalOrderBy,
          storeAs,
          keyReference,
        });
      }
    }
  };

  // logica para agregar
  const logicaAgregar = () => {
    // evaluamos si esta amndando un archivo y si este archivo aun no se a subido
    if (file && !storage.estado) {
      // priemro: subimos el archivo
      agregarArchivo();
    } else if (files && !storage) {
      // opcion para subir multiples archivos
      // primero: subimos los archivos
      agregarArchivos();
    } else {
      // segundo: agregamos el docuemnto
      agregarDocumento();
    }
  };

  // agregar un archivo
  const agregarArchivo = () => {
    setConsultaStorage({
      consulta: "subirUnArchivo",
      name: state.nombre,
      folder: collectionName,
      file: file,
    });
  };

  // agregar multiples archivos
  const agregarArchivos = () => {
    console.log("imagen guardada");
    setConsultaStorage({
      consulta: "subirArchivos",
      name: state.nombre,
      folder: `${collectionName}/${state.nombre}`,
      files,
    });
  };

  // agregar un documento
  const agregarDocumento = async () => {
    // evaluamos si se mando un archivo y si se subio correctamente
    if (file && url !== "") state.url = storage.estado;
    if (files && url !== "") state.imagenes = storage.imagenes;

    console.log(state);

    let res = await dispatch(
      setDocument({
        collectionName: collectionName,
        state: state,
      })
    );

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return { status: false, message: res };
    }

    return { status: true, message: res };
  };

  // logica para actualizar
  // NOTA: se usa ya que hay dos opciones de actualizar el documento, si cuanta o no con un archivo, continua...
  // NOTA: ...si no tiene archivo pregunta si lo quiere actualizar y despues lo actualiza el documento, continua...
  // NOTA: ...si  tiene archivo pregunta si lo quiere actualizar, despues lo elimina el archivo,  continua...
  // NOTA: .. sube el nuevo archivo y despues actualiza el docuemnto,
  const logicaActualizar = async () => {
    // evaluamos que el documento a actualizar tenga un archivo
    if (state.url && !storage.estado) {
      // cuenta con archivo y lo vamos a eliminar
      eliminarArchivo();
    } else if (!state.url) {
      // no cuenta con archivo, solo lo vamos a actualizar
      actualizarDocumento();
    } else if (storage.tipo === "eliminar" && storage.estado) {
      // cuenta con archivo y ya se elimino, vamos a subir el nuevo archivo
      agregarArchivo();
    } else {
      // cuenta con archivo y ya se elimino, ya se subio el nuevo, vamos a actualizarlo
      actualizarDocumento();
    }
  };

  // actualizar un documento
  const actualizarDocumento = async () => {
    // evaluamos si se mando un archivo y si se subio correctamente
    if (file && url !== "") state.url = storage.estado;

    let res = await dispatch(
      editDocument({
        collectionName: collectionName,
        id: id,
        state: state,
      })
    );

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return { status: false, message: res };
    }

    return { status: true, message: res };
  };

  // logica para eliminar
  // NOTA: se usa ya que hay dos opciones de eliminar el documento, si cuanta o no con un archivo, continua...
  // NOTA: ...si no tiene archivo pregunta si lo quiere eliminar y despues lo elimina el documento, continua...
  // NOTA: ...si  tiene archivo pregunta si lo quiere eliminar y despues lo elimina el archivo y despues el docuemnto, continua...
  const logicaEliminar = async () => {
    // evaluamos que el documento a eliminar tenga un archivo
    if (state.url && !storage.estado) {
      // cuenta con archivo y lo vamos a eliminar
      eliminarArchivo();
    } else if (!state.url) {
      // no cuenta con archivo
      eliminarDocumento();
    } else {
      // cuenta con archivo y ya se elimino
      eliminarDocumento();
    }
  };

  // eliminar un documento
  const eliminarDocumento = async () => {
    let res = await dispatch(
      deleteDocument({
        collectionName: collectionName,
        id: state.id,
      })
    );

    setResult(res.payload);

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return { status: false, message: res };
    }

    return { status: true, message: res };
  };

  // eliminar archivo
  const eliminarArchivo = async () => {
    setConsultaStorage({
      consulta: "eliminarUnArchivo",
      urlFile: state.url,
    });
  };

  useEffect(() => {
    switch (consulta) {
      case "obtenerDocumento":
        obtenerDocumento();
        break;
      case "obtenerCollecion":
        obtenerCollecion();
        break;
      case "obtenerRealTime":
        obtenerRealTime();
        break;
      case "agregarDocumento":
        logicaAgregar();
        break;
      case "actualizarDocumento":
        logicaActualizar();
        break;
      case "eliminarDocumento":
        logicaEliminar();
        break;
      case "reset":
        setConsultaStorage({
          consulta: "",
        });
        break;
      case "resetConsult":
        setResult({
          success: "reset",
        });
        break;
      default:
        break;
    }
    // if (!realtime) {
    //   // setResult(false);
    // } else {
    //   // consulta en realtime
    //   // setConsultaRealTime({ consulta: "reset" });
    //   // obtenerArray();
    // }
    // eslint-disable-next-line
  }, [consulta, realtime, storage]);

  return [result];
};

export default useDatabase;
